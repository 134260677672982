import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {ApplicationConfig, inject, provideAppInitializer} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideRouter, withHashLocation} from '@angular/router'
import routes from './app-routes'
import {authInterceptor} from './application/auth-interceptor'
import {responseInterceptor} from './application/response-interceptor'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withHashLocation()),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    provideAppInitializer(() => {
      return inject(ConfigService).bootstrap()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    }
  ]
}

